import React from "react";

import styles from "./Header.module.scss";

const Header = () => {
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-light  ${styles.header}`}
    >
      <a className="navbar-brand" href="#">
        Visa Database inc.
        <div className={styles.smallBrandName}>VisaDatabase.com</div>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <a className="nav-item nav-link active" href="#">
            Home <span className="sr-only">(current)</span>
          </a>
          <a className="nav-item nav-link" href="#">
            Features
          </a>
          <a className="nav-item nav-link" href="#">
            Pricing
          </a>
          <a className="nav-item nav-link disabled" href="#">
            Disabled
          </a>
        </div>
      </div> */}
    </nav>
  );
};

export default Header;
