export default function h1bReducer(state: any, action: any) {
  switch (action.type) {
    case "updateH1Bdata": {
      return action.h1bData;
      break;
    }

    case "API_ERROR": {
      return action;
      break;
    }

    default:
      break;
  }
}
